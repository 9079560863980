import { useCallback, useMemo } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { routes } from '@sevenrooms/routes'
import { useVenue } from './useVenue'

export function useWidgetLanguage() {
  const { matchQuery, updateQuery } = useNavigation()
  const { lang: urlLang } = matchQuery(routes.explore.reservations) ?? {}
  const { venueLanguages } = useVenue()
  const defaultLanguage = useMemo(() => venueLanguages.find(lang => lang.isDefault), [venueLanguages])
  const selectedLanguage: LanguageCode = useMemo(() => {
    if (venueLanguages.find(lang => lang.value === urlLang && lang.isEnabled)) {
      return urlLang as LanguageCode
    }
    return defaultLanguage?.value ?? 'en'
  }, [defaultLanguage?.value, urlLang, venueLanguages])
  const setSelectedLanguage = useCallback(
    (lang: LanguageCode) => {
      updateQuery(
        routes.explore.reservations,
        {
          lang,
        },
        'replaceIn'
      )
    },
    [updateQuery]
  )
  const availableLanguageOptions: SelectOption<LanguageCode>[] = useMemo(
    () =>
      venueLanguages
        .filter(lang => lang.isEnabled)
        .map(lang => ({
          id: lang.value,
          label: lang.name,
        })),
    [venueLanguages]
  )
  return {
    defaultLanguage,
    selectedLanguage,
    setSelectedLanguage,
    availableLanguageOptions,
  }
}

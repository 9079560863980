import { useCallback, useEffect, useMemo } from 'react'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { ReservationBreadcrumb, WidgetPortal } from '../../components'
import {
  useCachedCreateReservationHold,
  useLanguageStrings,
  useVenue,
  useReservationNavigation,
  useModifyReservationRoute,
  useWidgetSettings,
} from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { useGetGuestFacingUpgradeQuery, type UpdateFormStateUpgrades, useReservationFormState } from '../../store'
import {
  completeUpgradesPage,
  finishUpgradesPage,
  getSelectedUpgradesTotal,
  loadUpgradesPage,
  mapUpgradesToCategories,
  transformUpgradesDataToZodSchema,
} from '../../utils'
import { UpgradesFooter } from './UpgadesFooter'
import { UpgradesSection } from './UpgradesSection'
import { useUpgradesForm } from './useUpgradesForm'

export function Upgrades() {
  const { formatMessage } = useLocales()
  const widgetSettings = useWidgetSettings()
  const { id, currencyCode, name, urlKey } = useVenue()
  const { isModifyRoute } = useModifyReservationRoute()
  const { data: upgradeData, isLoading: isGuestFacingUpgradeLoading } = useGetGuestFacingUpgradeQuery(id)
  const {
    formState: {
      partySize: timeSlotPartySize,
      privateEventsPartySize,
      selectedTimeSlot,
      selectedExperience,
      categories: formCategories,
      actual,
    },
    updateFormState,
  } = useReservationFormState()
  const { navigateNext, navigatePrevious, navigateToFirstStep } = useReservationNavigation()
  const partySize = selectedExperience && privateEventsPartySize !== null ? privateEventsPartySize : timeSlotPartySize

  const [, { data: reservationHoldData, isLoading: isReservationHoldLoading }] = useCachedCreateReservationHold()
  const mappedUpgradesData = useMemo(
    () =>
      mapUpgradesToCategories(
        upgradeData?.inventories ?? [],
        upgradeData?.categories ?? [],
        selectedTimeSlot?.upsellCategories ?? [],
        selectedTimeSlot?.defaultServiceCharge ?? 0,
        selectedTimeSlot?.defaultGratuity ?? 0
      ),
    [
      selectedTimeSlot?.defaultGratuity,
      selectedTimeSlot?.defaultServiceCharge,
      selectedTimeSlot?.upsellCategories,
      upgradeData?.categories,
      upgradeData?.inventories,
    ]
  )
  const { isFetching: isFetchingLanguage } = useLanguageStrings()
  const showSkeleton = isFetchingLanguage || isGuestFacingUpgradeLoading || isReservationHoldLoading

  const form = useUpgradesForm({
    categories: transformUpgradesDataToZodSchema({
      categories: mappedUpgradesData,
      partySize,
      formCategories,
      actualSelectedUpsellsInformation: actual?.selectedUpsellsInformation,
    }),
  })
  const { field, handleSubmit, formState, reset, trigger } = form

  useEffect(() => {
    // Send the Google Analytics event for upgrades page load
    loadUpgradesPage(urlKey)
  }, [urlKey])

  useEffect(() => {
    if (mappedUpgradesData) {
      const defaultValues = {
        categories: transformUpgradesDataToZodSchema({
          categories: mappedUpgradesData,
          partySize,
          formCategories,
          actualSelectedUpsellsInformation: actual?.selectedUpsellsInformation,
        }),
      }
      reset(defaultValues)
    }
  }, [reset, mappedUpgradesData, formCategories, actual, partySize])

  const onSubmitSuccess = useCallback(
    (data: UpdateFormStateUpgrades) => {
      updateFormState(data)
      completeUpgradesPage(urlKey)
      finishUpgradesPage(urlKey)
      navigateNext()
    },
    [navigateNext, updateFormState, urlKey]
  )

  const onSubmitErrors = useCallback(errors => {
    // eslint-disable-next-line no-console
    console.error('errors', errors)
  }, [])

  const onSubmit = handleSubmit(onSubmitSuccess, onSubmitErrors)

  const categories = useWatch(field.prop('categories'))

  // This is required in order to update the category validation errors
  // Categories do not change, only the upgrades change.
  useEffect(() => {
    if (formState.isSubmitted) {
      trigger()
    }
  }, [categories, formState.isSubmitted, trigger])

  const price = useMemo(
    () => getSelectedUpgradesTotal(mappedUpgradesData, categories, widgetSettings.isFeesInPriceDisplayed),
    [mappedUpgradesData, categories, widgetSettings.isFeesInPriceDisplayed]
  )

  if (!selectedTimeSlot) {
    // If the user has not selected a time slot they are here in error
    // navigate them to the first enabled step
    navigateToFirstStep()
    return null
  }

  return (
    <>
      <Form {...form} onSubmit={onSubmitSuccess} onInvalid={onSubmitErrors}>
        <UpgradesSection
          categories={categories}
          upgradesData={mappedUpgradesData}
          field={field.prop('categories')}
          isLoading={showSkeleton}
          partySize={partySize}
          currencyCode={currencyCode}
        />
      </Form>
      <WidgetPortal portalId="upgrades-breadcrumbs">
        <ReservationBreadcrumb
          venueName={name}
          partySize={partySize}
          timeIso={selectedTimeSlot?.timeIso}
          onClick={navigatePrevious}
          isLoading={showSkeleton}
          holdEndTime={reservationHoldData?.holdEndTime}
          label={isModifyRoute ? formatMessage(reservationWidgetMessages.resWidgetNewReservationDetailsLabel) : undefined}
        />
      </WidgetPortal>
      <WidgetPortal portalId="footer-portal-id">
        <UpgradesFooter
          onClick={onSubmit}
          currencyCode={currencyCode}
          disabled={isGuestFacingUpgradeLoading}
          totalSum={price}
          isLoading={showSkeleton}
        />
      </WidgetPortal>
    </>
  )
}

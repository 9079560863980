import { useEffect, useCallback, useMemo, useRef } from 'react'
import { useHistory, useLocation, useNavigation, generatePath } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/routes'
import {
  useAppSelector,
  useAppDispatch,
  type ReservationWidgetStepName,
  mutateStepDisabledStatus,
  type ReservationWidgetFlow,
  updateFlow,
} from '../store'
import { useReleaseReservationHold } from './useReleaseReservationHold'
import { useVenue } from './useVenue'

interface UseReservationNavigationProps {
  onNavigateNext?: () => void
  onNavigatePrevious?: () => void
}

export function useReservationNavigation({ onNavigateNext, onNavigatePrevious }: UseReservationNavigationProps = {}) {
  const { urlKey: venueKey, newResWidgetEnabled } = useVenue()
  const { releaseReservationHold } = useReleaseReservationHold()
  const listenCallback = useRef<Function | undefined>()
  const { listen, goBack, length: historyLength } = useHistory()
  const { push, matchQuery, href } = useNavigation()
  const reservationRouteParams = matchQuery(routes.explore.reservations)
  const queryParamsRef = useRef(reservationRouteParams) // Be careful. queryParamsRef can have old param values. Should be fixed.
  queryParamsRef.current = reservationRouteParams
  const { pathname, search } = useLocation()
  if (!newResWidgetEnabled) {
    const searchParams = new URLSearchParams(search)
    const oldReservationWidgetPath = generatePath(
      `${routes.reservations.path}${searchParams.get('trackingSlug') || ''}`,
      { venueKey },
      { lang: searchParams.get('lang') }
    )
    window.location.replace(oldReservationWidgetPath)
  }
  const dispatch = useAppDispatch()
  const { flow, steps } = useAppSelector(state => state.reservationNavigation)
  // Kinda a nasty hack to make it so that I always reference the most up to date
  //  steps in the navigation callbacks
  const stepsRef = useRef(steps)
  stepsRef.current = steps
  const currentStep = useMemo(() => {
    const currentPath = getStepFromPathname(pathname)
    return steps.find(value => value.name === currentPath)
  }, [steps, pathname])

  const setActiveFlow = useCallback(
    (flow: ReservationWidgetFlow) => {
      dispatch(updateFlow({ flow }))
    },
    [dispatch]
  )
  const disableStep = useCallback(
    (step: ReservationWidgetStepName) => {
      dispatch(mutateStepDisabledStatus({ step, disabled: true }))
    },
    [dispatch]
  )
  const enableStep = useCallback(
    (step: ReservationWidgetStepName) => {
      dispatch(mutateStepDisabledStatus({ step, disabled: false }))
    },
    [dispatch]
  )

  // the listen() function from useHistory allows us listen for url chagnes
  //  and perform an action on url change, before page unmount.
  // We need this specific behavior so that an action that a component would expect to occur
  //  during a manual navigation would happen on a browser back/forward button press
  // the value returned by listen and stored by the ref is how we kill our listener
  // the useEffect is to make sure our callback is up to date with our internal params
  //    i.e. steps, currentStep, onNavigatePrevious, and onNavigateNext
  useEffect(() => {
    if (listenCallback.current) {
      // we need to clean up listenCallback each time after we called it
      listenCallback.current()
      listenCallback.current = undefined
    }
    listenCallback.current = listen((location, action) => {
      const currentPath = currentStep?.name
      if (currentPath && location.pathname.includes(currentPath)) {
        return
      }
      if (action === 'REPLACE') {
        listenCallback.current?.()
        listenCallback.current = undefined
        return
      }
      const newPath = getStepFromPathname(location.pathname)
      if (!newPath || !currentPath) {
        listenCallback.current?.()
        listenCallback.current = undefined
        return
      }
      const nextStepIndex = stepsRef.current.findIndex(value => value.name === newPath)
      const currentStepIndex = stepsRef.current.findIndex(value => value.name === currentPath)
      const nextStep = stepsRef.current[nextStepIndex]
      if (!nextStep) {
        listenCallback.current?.()
        listenCallback.current = undefined
        return
      }
      if (currentStepIndex > nextStepIndex) {
        onNavigatePrevious?.()
      } else {
        onNavigateNext?.()
      }
      listenCallback.current?.()
      listenCallback.current = undefined
    })
    return () => {
      if (listenCallback.current) {
        listenCallback.current()
        listenCallback.current = undefined
      }
    }
  }, [listen, onNavigateNext, onNavigatePrevious, currentStep, releaseReservationHold])

  // this releases any reservation hold when a user navigates away
  useEffect(() => {
    const listener = () => {
      releaseReservationHold()
    }
    window.addEventListener('unload', listener)
    return () => {
      window.removeEventListener('unload', listener)
    }
  }, [releaseReservationHold])

  /**
   * @param venueUrlKey if this param is included the function will hard navigate to the nextStep
   *  on the venue associated with venueUrlKey
   */
  const navigateNext = useCallback(
    (venueUrlKey?: string, query?: { [key: string]: string }) => {
      if (!currentStep) {
        return
      }
      const currentStepIndex = stepsRef.current.findIndex(step => step.name === currentStep.name)
      const { name } = stepsRef.current.find((step, index) => index > currentStepIndex && !step.disabled) ?? {}
      if (!name) {
        return
      }
      const route = getRouteFromFlowAndStep(flow, name)
      if (!route) {
        return
      }
      if (!venueUrlKey) {
        push(route, {
          params: { venueKey },
          query: getParams(query),
          queryMode: 'add',
        })
        return
      }
      const destination = href(route, {
        params: { venueKey: venueUrlKey },
        query: getParams(query),
        queryMode: 'replace',
      })
      window.location.assign(destination)
    },
    [flow, push, href, venueKey, currentStep]
  )

  /**
   * @param venueUrlKey if this param is included the function will hard navigate to the previousStep
   *  on the venue associated with venueUrlKey
   */
  const navigatePrevious = useCallback(
    (venueUrlKey?: string) => {
      // If historyLength <= 1 we've directly navigated to where we are,
      //     and "goBack" won't work as expected
      if (historyLength > 1) {
        goBack()
        return
      }
      if (!currentStep) {
        return
      }
      const currentStepIndex = stepsRef.current.findIndex(step => step.name === currentStep.name)
      const { name } =
        stepsRef.current
          .slice(0, currentStepIndex)
          .reverse()
          .find(step => !step.disabled) ?? {}
      if (!name) {
        return
      }
      const route = getRouteFromFlowAndStep(flow, name)
      if (!route) {
        return
      }
      if (!venueUrlKey) {
        push(route, {
          params: { venueKey },
          query: queryParamsRef.current,
          queryMode: 'add',
        })
        return
      }
      const destination = href(route, {
        params: { venueKey: venueUrlKey },
        query: queryParamsRef.current,
        queryMode: 'replace',
      })
      window.location.assign(destination)
    },
    [historyLength, flow, push, href, goBack, venueKey, currentStep]
  )

  const navigateToFirstStep = useCallback(() => {
    if (!venueKey) {
      return
    }
    const { name } = stepsRef.current.find(step => !step.disabled) ?? {}
    if (!name) {
      return
    }
    const route = getRouteFromFlowAndStep(flow, name)
    if (!route) {
      return
    }
    push(route, {
      params: { venueKey },
      query: queryParamsRef.current,
      queryMode: 'add',
    })
  }, [flow, venueKey, push])

  const navigateToOtherVenue = useCallback(
    (urlKey: string, query?: { [key: string]: string }) => {
      if (!currentStep) {
        return
      }
      const { name } = currentStep
      const route = getRouteFromFlowAndStep(flow, name)
      if (!route) {
        return
      }
      const destination = href(route, {
        params: { venueKey: urlKey },
        query: getParams(query),
        queryMode: 'replace',
      })
      window.location.assign(destination)
    },
    [flow, href, currentStep]
  )

  return {
    currentStep,
    disableStep,
    enableStep,
    navigateNext,
    navigatePrevious,
    navigateToFirstStep,
    navigateToOtherVenue,
    setActiveFlow,
  }
}

function getRouteFromFlowAndStep(flow: ReservationWidgetFlow, step: ReservationWidgetStepName) {
  if (step !== 'search') {
    return routes.explore.reservations[flow][step]
  }
  if (flow !== 'prearrival') {
    return routes.explore.reservations[flow][step]
  }
  return undefined
}

function getStepFromPathname(pathname: string) {
  const [explorePathVar, venueKeyPathVar, reservationPathVar, currentFlow, currentPath] = pathname.split('/').filter(value => !!value)
  if (!explorePathVar || !venueKeyPathVar || !reservationPathVar || !currentFlow || !currentPath) {
    return undefined
  }
  switch (currentPath) {
    case 'search':
    case 'upgrades':
    case 'checkout':
      return currentPath
    default:
      return undefined
  }
}

function getParams(query?: { [key: string]: string }) {
  const params = { ...query }
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.forEach((value, key) => {
    if (!params[key]) {
      params[key] = value
    }
  })
  return params
}

import { ExperiencesAvailabilityRangeService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

const experienceRangeApiTags = ['ExperienceRange']

export const experiencesAvailabilityRangeApi = baseReservationWidgetApi
  .enhanceEndpoints({ addTagTypes: experienceRangeApiTags })
  .injectEndpoints({
    endpoints: builder => ({
      getPrivateDiningExperiencesSingleDay: builder.query({
        providesTags: experienceRangeApiTags,
        queryFn: RTKUtils.rtkQuery(ExperiencesAvailabilityRangeService.getPrivateDiningExperiencesSingleDay),
      }),
    }),
  })

export const { useGetPrivateDiningExperiencesSingleDayQuery } = experiencesAvailabilityRangeApi

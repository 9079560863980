import type { Field } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, HStack, VStack, Box, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { SelectTip } from '../SelectTip'
import { SummaryRow } from '../SummaryRow'

const GRATUITY_MIN = 0
const GRATUITY_MAX = 39

export interface ReservationCheckoutOrderSummaryProps {
  guestCount: number
  seatingAreaDescription?: string
  baseAmount: number
  dueNowAmount: number
  gratuityAmount: number
  selectedGratuityAmount: number
  hasSelectableGratuity: boolean
  gratuityPercentageField: Field<number | null>
  onGratuityPercentageChange: (value: number | null) => void
  promoCodeDisplayName?: string
  promoCodeDiscountAmount: number
  giftCardDiscountAmount: number
  serviceChargeAmount: number
  subtotalAmount: number
  taxAmount: number
  upgradesAmounts: { quantity: number; name: string; totalPrice: number; isPriceHidden: boolean }[]
  venueCurrencyCode: string
  disabled: boolean
}

export function ReservationCheckoutOrderSummary({
  promoCodeDisplayName,
  guestCount,
  seatingAreaDescription,
  baseAmount,
  dueNowAmount,
  gratuityAmount,
  promoCodeDiscountAmount,
  giftCardDiscountAmount,
  serviceChargeAmount,
  subtotalAmount,
  taxAmount,
  gratuityPercentageField,
  selectedGratuityAmount,
  onGratuityPercentageChange,
  hasSelectableGratuity,
  upgradesAmounts,
  venueCurrencyCode,
  disabled,
}: ReservationCheckoutOrderSummaryProps) {
  const { formatMessage } = useLocales()

  const hasBaseAmount = baseAmount > 0
  const hasUpgrades = upgradesAmounts.length > 0

  if (!seatingAreaDescription && !hasBaseAmount && !hasUpgrades) {
    return null
  }

  const hasTotals = subtotalAmount > 0

  return (
    <CardSection p="lm" data-test="reservation-order-summary" borderColor="borders" backgroundColor="primaryBackground" borderRadius="s">
      <VStack spacing="lm">
        <VStack spacing="sm">
          <Text textStyle="h3">{formatMessage(reservationWidgetMessages.resWidgetSummaryLabel)}</Text>
          {!hasBaseAmount && seatingAreaDescription && <Text>{seatingAreaDescription}</Text>}
        </VStack>
        <VStack spacing="sm">
          {hasBaseAmount && (
            <SummaryRow
              count={guestCount}
              primaryText={
                !seatingAreaDescription ? formatMessage(reservationWidgetMessages.resWidgetReservationLabel) : seatingAreaDescription
              }
              secondaryText={FormatService.formatCurrency(baseAmount, venueCurrencyCode)}
            />
          )}
          {hasUpgrades &&
            upgradesAmounts.map(({ name, quantity, totalPrice, isPriceHidden }) => (
              <SummaryRow
                key={name}
                count={quantity}
                primaryText={name}
                secondaryText={isPriceHidden ? undefined : FormatService.formatCurrency(totalPrice, venueCurrencyCode)}
              />
            ))}
          {hasTotals && (
            <>
              <DividerLine ml="none" mr="none" />
              <SummaryRow
                primaryText={formatMessage(reservationWidgetMessages.resWidgetSubtotalLabel)}
                secondaryText={FormatService.formatCurrency(subtotalAmount, venueCurrencyCode)}
              />
              {!!promoCodeDiscountAmount && (
                <SummaryRow
                  primaryText={`${formatMessage(reservationWidgetMessages.resWidgetPromoCodeDiscountLabel)} (${promoCodeDisplayName})`}
                  secondaryText={FormatService.formatCurrency(-1 * promoCodeDiscountAmount, venueCurrencyCode)}
                  secondaryTextColor={promoCodeDiscountAmount ? 'successText' : undefined}
                />
              )}
              {!!serviceChargeAmount && (
                <SummaryRow
                  primaryText={formatMessage(reservationWidgetMessages.resWidgetServiceChargeLabel)}
                  secondaryText={FormatService.formatCurrency(serviceChargeAmount, venueCurrencyCode)}
                />
              )}
              {!!taxAmount && (
                <SummaryRow
                  primaryText={formatMessage(reservationWidgetMessages.resWidgetTaxLabel)}
                  secondaryText={FormatService.formatCurrency(taxAmount, venueCurrencyCode)}
                />
              )}
              {!!gratuityAmount && (
                <SummaryRow
                  primaryText={formatMessage(reservationWidgetMessages.resWidgetTipLabel)}
                  secondaryText={FormatService.formatCurrency(gratuityAmount, venueCurrencyCode)}
                />
              )}
              {hasSelectableGratuity && (
                <HStack spacing="s" alignItems="baseline">
                  <Label
                    noMargin
                    htmlFor="checkout-tip-percentage"
                    primary={formatMessage(reservationWidgetMessages.commonPaymentGeneralSelectATipLabel)}
                  />
                  <SelectTip
                    id="checkout-tip-percentage"
                    dataTest="sr-checkout-tip-percentage"
                    minValue={GRATUITY_MIN}
                    maxValue={GRATUITY_MAX}
                    tipField={gratuityPercentageField}
                    disabled={disabled}
                    onChange={onGratuityPercentageChange}
                  />
                  <Box ml="auto">
                    <Text>{FormatService.formatCurrency(selectedGratuityAmount, venueCurrencyCode)}</Text>
                  </Box>
                </HStack>
              )}
              {!!giftCardDiscountAmount && (
                <>
                  <DividerLine margin="none" />
                  <SummaryRow
                    primaryText={formatMessage(reservationWidgetMessages.resWidgetGiftCardDiscountLabel)}
                    secondaryText={FormatService.formatCurrency(-1 * giftCardDiscountAmount, venueCurrencyCode)}
                    secondaryTextColor={giftCardDiscountAmount ? 'successText' : undefined}
                  />
                </>
              )}
              <DividerLine margin="none" />
              <SummaryRow
                textStyle="body1Bold"
                primaryText={formatMessage(reservationWidgetMessages.commonTotalText)}
                secondaryText={FormatService.formatCurrency(dueNowAmount, venueCurrencyCode)}
              />
            </>
          )}
        </VStack>
      </VStack>
    </CardSection>
  )
}

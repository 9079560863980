import { Route, Switch, Redirect } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/routes'
import { CheckoutPage } from './Checkout'
import { UpgradesPage } from './Upgrades'

const prearrivalPath = routes.explore.reservations.prearrival

export function Prearrival() {
  return (
    <Switch>
      <Route path={prearrivalPath.upgrades.path}>
        <UpgradesPage />
      </Route>
      <Route path={prearrivalPath.checkout.path}>
        <CheckoutPage />
      </Route>
      <Redirect to={prearrivalPath.upgrades.path} />
    </Switch>
  )
}

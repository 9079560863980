import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { ManageReservationActual, ReservationWidget } from '@sevenrooms/core/domain'
import { getInitialReservationFormStateState } from './getInitialReservationFormStateState'
import type { AvailabilityTimeWithUpSellCost, PrivateEventsExperience } from '../../hooks/useAvailability'
import type { CheckoutForm } from '../../Reservations/Checkout/Checkout.zod'
import type { PrivateEventsSearchForm } from '../../Reservations/Search/PrivateEventsTab'
import type { SearchForm } from '../../Reservations/Search/Search.zod'
import type { UpgradesForm } from '../../Reservations/Upgrades/Upgrades.zod'
import type { RootState, AppDispatch } from '../store'

type ReplaceType<T, SourceType, TargetType> = {
  [K in keyof T]: T[K] extends SourceType ? TargetType : T[K]
}

export type UpdateFormStateSearch = ReplaceType<SearchForm, Date, string>
export type UpdateFormStatePrivateEventsSearch = ReplaceType<PrivateEventsSearchForm, Date | undefined, string>
export type UpdateFormStateUpgrades = ReplaceType<UpgradesForm, Date, string>
export type UpdateFormStateCheckout = ReplaceType<CheckoutForm, Date, string>

export type FormStateActual = ManageReservationActual & {
  timeSlotDescription: string
}

export type FormFieldState = Partial<
  UpdateFormStateSearch &
    UpdateFormStatePrivateEventsSearch &
    UpdateFormStateUpgrades &
    UpdateFormStateCheckout & {
      selectedTimeSlot: AvailabilityTimeWithUpSellCost | undefined
    } & {
      selectedExperience: PrivateEventsExperience | undefined
    } & {
      actual: FormStateActual
    } & {
      searchTab: ReservationWidget.SearchTab
    }
>

const reservationFormSlice = createSlice({
  name: 'reservationFormSlice',
  initialState: getInitialReservationFormStateState(),
  reducers: {
    clearFormState: () => getInitialReservationFormStateState(),
    updateFormState: (state, action: PayloadAction<FormFieldState>) => {
      const { selectedTimeSlot } = action.payload
      if (!selectedTimeSlot) {
        return {
          ...state,
          ...action.payload,
        }
      }
      return {
        ...state,
        ...action.payload,
        categories: action.payload.categories ?? {},
      }
    },
  },
})

export const { clearFormState, updateFormState } = reservationFormSlice.actions

export const useReservationFormState = () => {
  const dispatch = useDispatch<AppDispatch>()
  const formState = useSelector((state: RootState) => state.reservationForm)
  const updateFormStateCallback = useCallback(
    (data: FormFieldState) => {
      dispatch(updateFormState(data))
    },
    [dispatch]
  )
  const clearFormStateCallback = useCallback(() => {
    dispatch(clearFormState())
  }, [dispatch])
  return {
    formState,
    updateFormState: updateFormStateCallback,
    clearFormState: clearFormStateCallback,
  }
}

export const reservationFormReducer = reservationFormSlice.reducer

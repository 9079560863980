import styled from 'styled-components'
import type { Field } from '@sevenrooms/core/form'
import { type SelectOption, type SelectValue, StyledInput, StyledTarget, StyledValue } from '@sevenrooms/core/ui-kit/core'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SkeletonBox } from '../../../components/SkeletonBox'

export interface ReservationPartySizeSelectFormProps<T extends SelectOption<string | number>, E> {
  id: string
  label: string
  dataTest: string
  selectOptions: readonly T[]
  location: 'left' | 'center'
  placeholder?: string
  isLoading?: boolean
  field: Field<SelectValue<T, E>>
  onChange?: (value: SelectValue<T, E>) => void
  withEmpty?: E
}

const DESKTOP_HEIGHT = '84px'
const DESKTOP_LABEL_HEIGHT = '34px'
const MOBILE_HEIGHT = '58px'
const MOBILE_LABEL_HEIGHT = '16px'

export function ReservationSelectForm<T extends SelectOption<string | number>, E>({
  dataTest,
  isLoading,
  id,
  label,
  placeholder,
  selectOptions,
  location,
  field,
  onChange,
  withEmpty,
}: ReservationPartySizeSelectFormProps<T, E>) {
  return isLoading ? (
    <ReservationInputLoader location={location} />
  ) : (
    <StyledContainer location={location}>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <FormSelect
        id={id}
        field={field}
        data-test={dataTest}
        options={selectOptions}
        placeholder={placeholder}
        searchable={false}
        onChange={onChange}
        withNativeSupport
        withEmpty={withEmpty}
        contentProps={{ 'aria-label': label }}
      />
    </StyledContainer>
  )
}

export const StyledLabel = styled.label`
  z-index: ${props => props.theme.zIndex.default};
  color: ${props => props.theme.colors.primaryIcons};
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 10px) scale(1);
  padding: ${props => `${props.theme.spacing.xs} 0 ${props.theme.spacing.m} ${props.theme.spacing.m}`};
  font: ${props => props.theme.textStyles.body1};
  pointer-events: none;
  line-height: ${DESKTOP_LABEL_HEIGHT};

  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    font: ${props => props.theme.textStyles.body2};
    line-height: ${MOBILE_LABEL_HEIGHT};
  }
`

const StyledContainer = styled(Box)<{ location: 'left' | 'center' }>`
  ${StyledTarget} {
    font: ${props => props.theme.textStyles.h2};
    height: ${DESKTOP_HEIGHT};
    line-height: ${DESKTOP_HEIGHT};
    border-radius: ${props => {
      const borderRadius = props.location === 'left' ? props.theme.borderRadius.s : 0
      return `${borderRadius} 0 0 ${borderRadius}`
    }};
  }
  ${StyledInput} {
    height: ${DESKTOP_HEIGHT};
  }
  ${StyledValue} {
    font: ${props => props.theme.textStyles.h2};
    line-height: inherit;
    padding-top: 14px;
    color: ${props => props.theme.colors.primaryFont};
  }

  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    ${StyledTarget} {
      font: ${props => props.theme.textStyles.body1Bold};
      height: ${MOBILE_HEIGHT};
      line-height: ${MOBILE_HEIGHT};
    ${StyledInput} {
      height: ${MOBILE_HEIGHT};
    }
    ${StyledValue} {
      font: ${props => props.theme.textStyles.body1Bold};
      line-height: inherit;
      padding-top: ${props => props.theme.spacing.s};
    }
  }
`

interface ReservationInputLoaderProps {
  location: 'left' | 'center' | 'right'
}
export function ReservationInputLoader({ location }: ReservationInputLoaderProps) {
  return (
    <StyledFakePartySizeField location={location}>
      <SkeletonBox maxWidth="40px" height="16px" borderWidth="none" borderRadius="s" />
      <SkeletonBox maxWidth="120px" width="80%" height="16px" borderWidth="none" borderRadius="s" />
    </StyledFakePartySizeField>
  )
}

interface StyledFakePartySizeFieldProps {
  location: 'left' | 'center' | 'right'
}
export const StyledFakePartySizeField = styled.div<StyledFakePartySizeFieldProps>`
  box-sizing: border-box;
  border: solid ${props => props.theme.colors.borders} ${props => props.theme.borderWidth.s};
  border-left-width: ${props => (props.location === 'left' ? props.theme.borderWidth.s : props.theme.borderWidth.none)};
  border-radius: ${props => {
    if (props.location === 'left') {
      return `${props.theme.borderRadius.s} 0 0 ${props.theme.borderRadius.s}`
    } else if (props.location === 'right') {
      return `0 ${props.theme.borderRadius.s} ${props.theme.borderRadius.s} 0`
    }
    return 0
  }};
  background-color: ${props => props.theme.colors.primaryBackground};
  padding: ${props => `${props.theme.spacing.lm} 0 ${props.theme.spacing.m} ${props.theme.spacing.m}`};
  font: ${props => props.theme.textStyles.body1};

  & > div:first-child {
    margin-bottom: 6px;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    padding-top: 9px;
    height: ${MOBILE_HEIGHT};
  }
`

import { type PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import type { BaseVenue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { matchPath, useLocation } from '@sevenrooms/core/navigation'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { useMaxWidthBreakpoint, useMeasure, useScrollBreakpoints } from '@sevenrooms/core/ui-kit/hooks'
import { Box, Flex, VStack, LogoFooter, TopNav, type TopNavProps } from '@sevenrooms/core/ui-kit/layout'
import { routes } from '@sevenrooms/routes'
import { HeaderImage, DEFAULT_HEADER_IMAGE_HEIGHT } from '../HeaderImage'
import { widgetContainerMessages } from './widgetContainerMessages'

type Venue = Pick<BaseVenue, 'largeLogoUrl' | 'urlKey'>

const WIDGET_CONTAINER_CONTENT_MAX_WIDTH = '1440px'
// Height of header for modify flow including margins
const BREADCRUMBS_GROW_BREAKPOINT = 60

export interface WidgetContainerProps extends Venue {
  venueName: string
  headerImageUrl?: string
  headerImageHeight?: number
  showTopNav?: boolean
  enableGrowingTopNav?: boolean
  enableGrowingBreadcrumbs?: boolean
  stickyFooter?: boolean
  showFooter?: boolean
  languagePicker?: TopNavProps['languagePicker']
  sevenRoomsLogin?: TopNavProps['sevenRoomsLogin']
  showGutters?: boolean
  breadcrumbsPortalId?: string
  footerPortalId?: string
}

export function WidgetContainer({
  urlKey,
  largeLogoUrl,
  venueName,
  children,
  headerImageUrl,
  headerImageHeight = DEFAULT_HEADER_IMAGE_HEIGHT,
  showTopNav = true,
  enableGrowingTopNav = false,
  showFooter = true,
  showGutters = false,
  languagePicker,
  breadcrumbsPortalId,
  enableGrowingBreadcrumbs = false,
  footerPortalId,
  sevenRoomsLogin,
}: PropsWithChildren<WidgetContainerProps>) {
  const { formatMessage } = useLocales()
  const { topNav } = useTheme()
  const [ref, { height }] = useMeasure({ debounce: 200 })
  const shouldTopNavGrow = useShouldTopNavGrow(enableGrowingTopNav, !!headerImageUrl, headerImageHeight)
  const shouldBreadcrumbsGrow = useShouldBreadcrumbsGrow(enableGrowingBreadcrumbs, height)
  const isMobile = useMaxWidthBreakpoint('s')
  const padding = isMobile ? 'm' : 'lm'
  const { pathname } = useLocation()
  const isManageRoute =
    !!matchPath(pathname, { path: routes.explore.reservations.manage.path }) ||
    !!matchPath(pathname, { path: routes.explore.reservations.alerts.path })
  const venuePath = isManageRoute ? `/reservations/${urlKey}` : `/explore/${urlKey}/reservations/create/search`
  return (
    <VStack justifyContent="space-between" minHeight="100vh" backgroundColor={showGutters ? 'secondaryBackground' : 'primaryBackground'}>
      <VStack width="100%" flex="1">
        {showTopNav && (
          <TopNav
            logoLabel={venueName}
            baseUrl={venuePath}
            logo={largeLogoUrl}
            grow={shouldTopNavGrow}
            languagePicker={languagePicker}
            sevenRoomsLogin={sevenRoomsLogin}
          />
        )}
        {headerImageUrl && (
          <HeaderImage
            alt={formatMessage(widgetContainerMessages.headerAlt, {
              venue_name: venueName ?? formatMessage(widgetContainerMessages.venue),
            })}
            maxWidth={showGutters ? WIDGET_CONTAINER_CONTENT_MAX_WIDTH : undefined}
            height={headerImageHeight}
            src={headerImageUrl}
          />
        )}
        {breadcrumbsPortalId && (
          <BreadcrumbsContainer backgroundColor="primaryBackground" position="sticky" boxShadow="tertiary" zIndex="default">
            <Box id={breadcrumbsPortalId} ref={ref} width="100%" />
            {shouldBreadcrumbsGrow && <Box width="100%" height={`${topNav.spacerHeight}px`} />}
          </BreadcrumbsContainer>
        )}
        <Flex
          maxWidth={showGutters ? WIDGET_CONTAINER_CONTENT_MAX_WIDTH : undefined}
          width="100%"
          alignSelf="center"
          justifyContent="center"
          pl={padding}
          pr={padding}
          data-test="widget-content"
          flex="1"
          backgroundColor={showGutters ? 'primaryBackground' : undefined}
        >
          {children}
        </Flex>
        {footerPortalId && (
          <Box
            id={footerPortalId}
            boxShadow="primaryUp"
            zIndex="overlay"
            pl={padding}
            pr={padding}
            position="sticky"
            bottom="none"
            width="100%"
            backgroundColor="primaryBackground"
          />
        )}
      </VStack>
      {showFooter && <LogoFooter />}
    </VStack>
  )
}

function useShouldTopNavGrow(topNavGrow: boolean, hasHeaderImage: boolean, headerImageHeight: number) {
  const { topNav } = useTheme()
  const scrollBreakPoint = useMemo(() => ({ y: headerImageHeight - topNav.spacerHeight }), [topNav, headerImageHeight])
  const { y } = useScrollBreakpoints(scrollBreakPoint)
  return topNavGrow && (!hasHeaderImage || y)
}

function useShouldBreadcrumbsGrow(enable: boolean, breadcrumbsHeight: number) {
  const scrollBreakPoint = useMemo(() => ({ y: BREADCRUMBS_GROW_BREAKPOINT + breadcrumbsHeight }), [breadcrumbsHeight])
  const { y } = useScrollBreakpoints(scrollBreakPoint)
  return enable && y
}

const BreadcrumbsContainer = styled(VStack)`
  top: ${props => `calc(${props.theme.topNav.height.desktop} + 1px)`};
  @media (max-width: ${props => props.theme.breakpoints.m}px) {
    top: ${props => `calc(${props.theme.topNav.height.mobile} + 1px)`};
  }
`

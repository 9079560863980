import { WidgetLanguageService, RTKUtils, type GetWidgetLanguageStringsArgs, type WidgetLanguageAdapter } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

interface WidgetLanguageResult extends ReturnType<typeof WidgetLanguageAdapter.toClient> {}
export const widgetLanguageApi = baseReservationWidgetApi.injectEndpoints({
  endpoints: builder => ({
    getLanguageStrings: builder.query<WidgetLanguageResult, GetWidgetLanguageStringsArgs>({
      queryFn: RTKUtils.rtkQuery(WidgetLanguageService.getLanguageStrings),
    }),
  }),
})

export const { useGetLanguageStringsQuery } = widgetLanguageApi

import styled from 'styled-components'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeFieldHeight } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { trackTealiumChangeTime } from '../../utils'
import type { CSSProperties } from 'react'

export type ReservationTimeSlotVariant = 'primary' | 'secondary'

export interface ReservationTimeslotProps {
  time: string
  timeslotDescription?: string
  variant: ReservationTimeSlotVariant
  onClick: () => void
  size?: ThemeFieldHeight
  width?: CSSProperties['width']
}

export function ReservationTimeslot({ time, size = 'l', timeslotDescription, variant, onClick, width }: ReservationTimeslotProps) {
  return (
    <Box width={width}>
      <TimeSlotButton
        data-test={`reservation-timeslot-button-${time}-${timeslotDescription}`}
        noPadding
        variant={variant}
        onClick={() => {
          onClick()
          trackTealiumChangeTime(TimeOnly.fromSafe(time)?.toHoursMinutesIso())
        }}
        width={width}
        fullWidth
        size={size}
      >
        <VStack p="s" spacing="xs" justifyContent="space-around" alignItems="center" width="100%">
          <Text textStyle="body1Bold" data-test="reservation-timeslot-button-time" textAlign="center" width="100%" color="inherit">
            {time}
          </Text>
          {timeslotDescription && (
            <Text
              textStyle="body2"
              data-test="reservation-timeslot-button-description"
              textAlign="center"
              textOverflow="ellipsis"
              width="100%"
              color="inherit"
            >
              {timeslotDescription}
            </Text>
          )}
        </VStack>
      </TimeSlotButton>
    </Box>
  )
}

const TimeSlotButton = styled(Button)<{ width: CSSProperties['width'] }>`
  ${props =>
    props.width &&
    `
      width: ${props.width};
    `}
`

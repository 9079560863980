import { useCarousel, Carousel } from 'nuka-carousel'
import styled from 'styled-components'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'

interface ImagesCarouselProps {
  images: string[]
  height?: string
}

export function ImagesCarousel({ images, height }: ImagesCarouselProps) {
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      borderRadius="s"
      overflow="hidden"
      data-test="room-images"
    >
      <StyledCarousel showDots dots={<CarouselDots />} showArrows arrows={<CarouselArrows />}>
        {images.map(src => (
          <StyledImage src={src} key={src} maxHeight={height} width="100%" alt="image" />
        ))}
      </StyledCarousel>
    </VStack>
  )
}
export function CarouselDots() {
  const { totalPages, currentPage, goToPage } = useCarousel()

  return (
    <HStack spacing="s" mt="sm">
      {[...Array(totalPages)].map((_, index) => (
        <StyledDot
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-dot`}
          onClick={() => goToPage(index)}
          isActive={index === currentPage}
          data-test={`${index + 1}-dot`}
        />
      ))}
    </HStack>
  )
}

export function CarouselArrows() {
  const { currentPage, totalPages, goBack, goForward } = useCarousel()

  const enablePrevNavButton = currentPage > 0
  const enableNextNavButton = currentPage < totalPages - 1

  return (
    <div>
      <StyledArrow p="none m" visibility={enablePrevNavButton ? 'initial' : 'hidden'}>
        <IconButton icon="VMSWeb-chevron-left" iconBackgroundColor="primaryBackground" data-test="chevron-left" onClick={goBack} />
      </StyledArrow>
      <StyledArrow p="none m" isRight visibility={enableNextNavButton ? 'initial' : 'hidden'}>
        <IconButton icon="VMSWeb-chevron-right" iconBackgroundColor="primaryBackground" data-test="chevron-right" onClick={goForward} />
      </StyledArrow>
    </div>
  )
}

export const StyledImage = styled.img<{ maxHeight?: string }>`
  object-fit: cover;
  object-position: center center;
  min-width: 100%;
  max-height: ${props => props.maxHeight || '100%'};
`

const StyledCarousel = styled(Carousel)`
  width: 100%;
`

const StyledDot = styled(Button)<{ isActive: boolean }>`
  padding: 0;
  width: 8px;
  height: 8px;
  background-color: ${props => (props.isActive ? props.theme.colors.brandColor : props.theme.colors.primaryBackground)};
  border: ${props => `${props.theme.borderWidth.s} solid ${props.theme.colors.secondaryFont}`};
`

const StyledArrow = styled(Box)<{ isRight?: boolean }>`
  position: absolute;
  top: calc(50% - 2rem);
  right: ${props => (props.isRight ? 0 : 'auto')};
`

import { HStack } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeColor, ThemeTextStyle } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface SummaryRowProps {
  count?: number
  primaryText: string
  secondaryText?: string
  textStyle?: ThemeTextStyle
  primaryTextColor?: ThemeColor
  secondaryTextColor?: ThemeColor
  'data-test'?: string
}

export function SummaryRow({
  count,
  primaryText,
  secondaryText,
  textStyle,
  primaryTextColor,
  secondaryTextColor,
  'data-test': dataTest,
}: SummaryRowProps) {
  return (
    <HStack justifyContent="space-between" data-test={dataTest}>
      {count ? (
        <HStack spacing="sm">
          <Text color={primaryTextColor} textStyle={textStyle}>
            {count}x
          </Text>
          <Text color={primaryTextColor} textStyle={textStyle}>
            {primaryText}
          </Text>
        </HStack>
      ) : (
        <Text color={primaryTextColor} textStyle={textStyle}>
          {primaryText}
        </Text>
      )}
      {secondaryText && (
        <Text color={secondaryTextColor} textStyle={textStyle}>
          {secondaryText}
        </Text>
      )}
    </HStack>
  )
}

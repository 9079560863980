import { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import { FormatService, useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { VStack, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { getMinSpendValueForSelectedTime } from '../../utils'
import { SummaryRow } from '../SummaryRow'
import type { PrivateEventsExperience } from '../../hooks/useAvailability'

export interface PrivateEventsSummaryProps {
  experience: PrivateEventsExperience
  timeIso: string
  currencyCode: string
}

export function PrivateEventsSummary({ experience, timeIso, currencyCode }: PrivateEventsSummaryProps) {
  const { formatMessage } = useLocales()
  const policy = formatMessage(reservationWidgetMessages.resWidgetGroupBookingPolicyBody)
  const minSpendValue = useMemo(() => getMinSpendValueForSelectedTime(experience, timeIso), [experience, timeIso])
  const hasPolicy = useMemo(() => (policy ? !!sanitizeHtml(policy, { allowedTags: [] }).trim() : false), [policy])

  if (minSpendValue === undefined && !experience.fees?.length && !hasPolicy) {
    return null
  }

  return (
    <CardSection p="lm" data-test="private-events-summary" borderColor="borders" backgroundColor="primaryBackground" borderRadius="s">
      <VStack spacing="lm">
        <Text textStyle="h3">{formatMessage(reservationWidgetMessages.resWidgetGroupBookingSummaryLabel)}</Text>
        {(minSpendValue !== undefined || !!experience.fees?.length) && (
          <VStack spacing="sm">
            {minSpendValue !== undefined && (
              <>
                <Text textStyle="body1Bold">{formatMessage(reservationWidgetMessages.resWidgetGroupBookingSummarySubLabel)}</Text>
                <SummaryRow
                  data-test="min-spend-value"
                  primaryText={formatMessage(reservationWidgetMessages.resWidgetGroupBookingSummaryMinSpendLabel)}
                  secondaryText={FormatService.formatCurrency(minSpendValue, currencyCode)}
                />
              </>
            )}
            {experience.fees?.map(fee => (
              <SummaryRow primaryText={fee.label} key={fee.id} data-test={`${fee.id}-fee`} secondaryText={`${fee.value}%`} />
            ))}
          </VStack>
        )}
        {hasPolicy && policy && (
          <VStack spacing="s" data-test="private-events-policy">
            <Text textStyle="body1Bold" data-test="policy-body">
              {formatMessage(reservationWidgetMessages.resWidgetGroupBookingPolicyLabel)}
            </Text>
            <Text data-test="policy-body">
              <HTMLContent content={policy} allowedTags={false} />
            </Text>
          </VStack>
        )}
      </VStack>
    </CardSection>
  )
}

import type { PhotoDict, ExperienceAvailabilitySingleDay } from '@sevenrooms/core/domain'
import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import type { PrivateEventsExperience } from '../hooks/useAvailability'

export function getImageURlFromPhotoDict(photoDict: PhotoDict) {
  // it seems some bug on BE photoDict.large can be undefined, using raw in this case
  const photoDictURL = photoDict.large || photoDict.raw
  return photoDictURL ? `/.h/download/${photoDictURL}` : ''
}

export function getFeesFromExperience(experience: ExperienceAvailabilitySingleDay, adminFeeLabel: string) {
  const fees = []
  if (experience.taxInfo && experience.taxInfo.id === experience.pdrTaxRateId) {
    fees.push({
      label: experience.taxInfo.name ?? '',
      value: experience.taxInfo.value,
      id: experience.taxInfo.id,
    })
  }
  if (experience.pdrAdminFee !== null) {
    fees.push({ label: adminFeeLabel, value: experience.pdrAdminFee, id: 'admin_fee_id' })
  }
  return fees
}

// BE returns to FE days of week keys like SUNDAY, MONDAY etc
const dayOfWeekMap: Record<string, number> = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

const START_OF_DAY_ISO_TIME = '00:00:00'

export function getMinSpendMatrixFromExperience(experience: ExperienceAvailabilitySingleDay) {
  return experience.pdrMinimumSpendMatrix.map(minSpend => ({
    minimumSpend: minSpend.minimumSpend,
    startDay: dayOfWeekMap[minSpend.startDay] as number,
    endDay: dayOfWeekMap[minSpend.endDay] as number,
    startTime: minSpend.startTime,
    endTime: minSpend.endTime,
  }))
}

export function getTotalMinCapacity(experience: ExperienceAvailabilitySingleDay) {
  if (experience.pdrMinSeatedCapacity === null || experience.pdrMinSeatedCapacity === undefined) {
    return experience.pdrStandingMinCapacity ?? undefined
  }
  if (experience.pdrStandingMinCapacity === null || experience.pdrStandingMinCapacity === undefined) {
    return experience.pdrMinSeatedCapacity
  }
  return Math.min(experience.pdrMinSeatedCapacity, experience.pdrStandingMinCapacity)
}

export function getTotalMaxCapacity(experience: ExperienceAvailabilitySingleDay) {
  if (experience.pdrMaxSeatedCapacity === null || experience.pdrMaxSeatedCapacity === undefined) {
    return experience.pdrStandingCapacity ?? undefined
  }
  if (experience.pdrStandingCapacity === null || experience.pdrStandingCapacity === undefined) {
    return experience.pdrMaxSeatedCapacity
  }
  return Math.max(experience.pdrStandingCapacity, experience.pdrMaxSeatedCapacity)
}

function removeFilePathFromFileName(fileName: string) {
  return fileName.replace(/\..+$/, '')
}

export function getMenuFromExperience(experience: ExperienceAvailabilitySingleDay) {
  return experience.menuBlobId
    ? { name: removeFilePathFromFileName(experience.menuFilename), url: `/.h/download/${experience.menuBlobId}` }
    : undefined
}

export function getMinSpendValueForSelectedTime(experience: PrivateEventsExperience, timeIso: string) {
  const timeOnly = TimeOnly.from(timeIso)
  const dateOnly = DateOnly.from(timeIso)
  const timeSlotDayOfWeek = dateOnly?.toJsDate().getDay()
  const timeSlotISOTime = timeOnly?.toIso()

  const minSpend = experience.minSpendMatrix?.find(
    value =>
      value.startDay <= timeSlotDayOfWeek &&
      value.endDay >= timeSlotDayOfWeek &&
      (!value.startTime || value.startTime === START_OF_DAY_ISO_TIME || value.startTime <= timeSlotISOTime) &&
      (!value.endTime || value.endTime === START_OF_DAY_ISO_TIME || value.endTime >= timeSlotISOTime)
  )?.minimumSpend

  return minSpend !== undefined ? minSpend : experience.minSpendDefault
}

export function getMinSpendRange(experience: PrivateEventsExperience) {
  let minSpend = experience.minSpendDefault
  let maxSpend = experience.minSpendDefault
  experience.minSpendMatrix?.forEach(value => {
    if (!minSpend || value.minimumSpend < minSpend) {
      minSpend = value.minimumSpend
    } else if (!maxSpend || value.minimumSpend > maxSpend) {
      maxSpend = value.minimumSpend
    }
  })

  return [minSpend, maxSpend]
}

export function sortExperiences(a: PrivateEventsExperience, b: PrivateEventsExperience) {
  const timeslotsCountA = a.availabilities?.length ?? 0
  const timeslotsContB = b.availabilities?.length ?? 0
  const maxPartySizeA = Math.max(a.standingCapacityMax || 0, a.seatedCapacityMax || 0)
  const maxPartySizeB = Math.max(b.standingCapacityMax || 0, b.seatedCapacityMax || 0)
  if (timeslotsCountA > timeslotsContB) {
    return -1
  } else if (timeslotsContB > timeslotsCountA) {
    return 1
  }
  if (maxPartySizeA > maxPartySizeB) {
    return -1
  } else if (maxPartySizeB > maxPartySizeA) {
    return 1
  }
  if (a.name > b.name) {
    return 1
  } else if (b.name > a.name) {
    return -1
  }
  return 0
}

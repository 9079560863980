import { useLocales } from '@sevenrooms/core/locales'
import { Switch, Route } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/routes'
import { ReservationStatus, BookReservation, WidgetContainer, ManageReservationContainer } from '../../components'
import { useVenue, useGetSevenroomsLogin } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { Manage } from './Manage'

const managePath = routes.explore.reservations.manage

export function ManageReservation() {
  const { formatMessage } = useLocales()
  const { largeLogoUrl, urlKey, name } = useVenue()

  const sevenRoomsLogin = useGetSevenroomsLogin()

  return (
    <WidgetContainer largeLogoUrl={largeLogoUrl} urlKey={urlKey} venueName={name} sevenRoomsLogin={sevenRoomsLogin}>
      <ManageReservationContainer portalId="manage-res-portal">
        <Switch>
          <Route path={managePath.canceled.path}>
            <ReservationStatus header={formatMessage(reservationWidgetMessages.resCancelPagePageHeader)}>
              <BookReservation />
            </ReservationStatus>
          </Route>
          <Route path={managePath.passed.path}>
            <ReservationStatus header={formatMessage(reservationWidgetMessages.resCancelPageResPassed)}>
              <BookReservation />
            </ReservationStatus>
          </Route>
          <Route path={managePath.path}>
            <Manage />
          </Route>
        </Switch>
      </ManageReservationContainer>
    </WidgetContainer>
  )
}

import { useMemo } from 'react'
import { matchPath, useLocation, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/routes'

export const useModifyReservationRoute = () => {
  const { matchQuery, matchParams } = useNavigation()
  const { pathname } = useLocation()
  const { venueKey } = matchParams(routes.explore) ?? {}
  const { lang, token } = matchQuery(routes.explore.reservations.modify) ?? {}

  const isModifyRoute = useMemo(() => !!matchPath(pathname, { path: routes.explore.reservations.modify.path }), [pathname])

  return {
    token,
    lang,
    venueKey,
    isModifyRoute,
  }
}

import { useCallback, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { routes } from '@sevenrooms/routes'
import { useCancelRequestMutation } from '../../../domain/request'
import { useAlertsRoute, useVenue } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useModals } from '../../../store'
import { PriorityAlertDetails } from '../PriorityAlertDetails'

export function PriorityAlert() {
  const { showErrorModal } = useModals()
  const { push } = useNavigation()
  const venue = useVenue()
  const { formatMessage } = useLocales()
  const { requestId, partySize, startTime, endTime, date } = useAlertsRoute()
  const [isCancelDisabled, setCancelDisabled] = useState(!requestId)

  const [cancelRequest] = useCancelRequestMutation()

  const onCancelAlertClick = useCallback(async () => {
    try {
      setCancelDisabled(true)
      await cancelRequest({ requestId }).unwrap()
      push(routes.explore.reservations.alerts.canceled, {
        params: { venueKey: venue.urlKey },
      })
    } catch (e) {
      showErrorModal()
    } finally {
      setCancelDisabled(false)
    }
  }, [cancelRequest, push, requestId, showErrorModal, venue.urlKey])

  return (
    <VStack spacing="m" width="100%">
      <PriorityAlertDetails partySize={partySize} date={date} startTime={startTime} endTime={endTime} />
      <Button
        variant="secondary-warning"
        data-test="alert-cancel-button"
        fullWidth
        disabled={isCancelDisabled}
        onClick={onCancelAlertClick}
      >
        {formatMessage(reservationWidgetMessages.resCancelPageCancelPriorityAlertsButton)}
      </Button>
    </VStack>
  )
}

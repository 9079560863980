import { useLocales } from '@sevenrooms/core/locales'
import { Modal, ModalBody, Loader } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

export function ReservationHoldModal() {
  const { formatMessage } = useLocales()
  return (
    <Modal ariaLabel="Modal" width="100%" minWidth="max-content" data-test="sr-res-hold-modal">
      <ModalBody>
        <Loader align="bottom">{formatMessage(reservationWidgetMessages.resWidgetReservationHoldLoadingMessage)}</Loader>
      </ModalBody>
    </Modal>
  )
}

import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { policyDetailsMessages } from './policyDetailsMessages'
import { PolicyWrapper } from './PolicyWrapper'

export interface PolicyDetailsProps {
  bookingCheckoutPolicy?: string
  cancellationPolicy?: string
  showSpecialAttentionMessage: boolean
  dataTestId?: string
}

export function PolicyDetails({ showSpecialAttentionMessage, bookingCheckoutPolicy, cancellationPolicy, dataTestId }: PolicyDetailsProps) {
  const { formatMessage } = useLocales()

  if (!bookingCheckoutPolicy && !cancellationPolicy && !showSpecialAttentionMessage) {
    return null
  }

  return (
    <VStack spacing="l">
      {bookingCheckoutPolicy && (
        <PolicyWrapper
          header={formatMessage(policyDetailsMessages.bookingCheckoutPolicyHeader)}
          content={bookingCheckoutPolicy}
          dataTest={dataTestId && `booking-${dataTestId}`}
        />
      )}
      {showSpecialAttentionMessage && (
        <PolicyWrapper
          header={formatMessage(policyDetailsMessages.specialAttentionMessageHeader)}
          content={formatMessage(policyDetailsMessages.specialAttentionMessageBody)}
          dataTest={dataTestId && `special-attention-${dataTestId}`}
        />
      )}
      {cancellationPolicy && (
        <PolicyWrapper
          header={formatMessage(policyDetailsMessages.cancellationPolicyHeader)}
          content={cancellationPolicy}
          dataTest={dataTestId && `cancellation-${dataTestId}`}
        />
      )}
    </VStack>
  )
}

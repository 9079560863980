import { useMemo } from 'react'
import type { ManageReservationCalendarLinks } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, TimeInterval, TimeOnly } from '@sevenrooms/core/timepiece'
import { VStack, AutoFallbackImage, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { ReservationDetailFormatService, ReservationDetailsHeader, ReservationDetailsItem } from './components'
import { reservationDetailsMessages } from './reservationDetailsMessages'

export interface ReservationDetailCardProps {
  calendarLinks?: ManageReservationCalendarLinks
  fallback: JSX.Element
  imageUrl: string
  venueName: string
  timeSlotDescription?: string
  guestCount?: number | null
  femaleCount?: number | null
  maleCount?: number | null
  guestDisplayName?: string
  reservationDate?: string
  reservationTime?: string
  reservationDuration?: number
  reservationMinimum?: string
  reservationReferenceId?: string
  seatingAreaDescription?: string
  oldMaxGuest?: number
  oldReservationDuration?: number
  oldReservationTime?: string
  oldReservationMinimum?: string
  oldReservationDate?: string
  oldSeatingAreaDescription?: string
  oldTimeSlotDescription?: string
  offerName?: string
}

export function ReservationDetailCard({
  calendarLinks,
  fallback,
  imageUrl,
  reservationDate,
  reservationTime,
  reservationDuration,
  reservationMinimum,
  guestCount,
  femaleCount,
  maleCount,
  guestDisplayName,
  seatingAreaDescription,
  timeSlotDescription,
  reservationReferenceId,
  venueName,
  offerName,
  oldMaxGuest,
  oldReservationDuration,
  oldReservationTime,
  oldReservationMinimum,
  oldReservationDate,
  oldSeatingAreaDescription,
  oldTimeSlotDescription,
}: ReservationDetailCardProps) {
  const { formatMessage } = useLocales()
  const arrivalDate = DateOnly.fromSafe(reservationDate)
  const arrivalTime = TimeOnly.fromSafe(reservationTime)
  const partySizeAmount = useMemo(
    () => ReservationDetailFormatService.formatPartySize(guestCount, femaleCount, maleCount),
    [femaleCount, guestCount, maleCount]
  )
  const formattedPartySize = partySizeAmount ? `${formatMessage(reservationDetailsMessages.partySize)} ${partySizeAmount}` : undefined

  const formattedOldPartySize = useMemo(
    () => (oldMaxGuest ? `${formatMessage(reservationDetailsMessages.partySize)} ${oldMaxGuest}` : ''),
    [oldMaxGuest, formatMessage]
  )

  const formattedOldReservationMinimum = useMemo(
    () => (oldReservationMinimum ? `${formatMessage(reservationDetailsMessages.tableMinimum)} ${oldReservationMinimum}` : ''),
    [oldReservationMinimum, formatMessage]
  )

  const formattedOldSeatingAreaDescription = useMemo(
    () => (oldSeatingAreaDescription ? `${formatMessage(reservationDetailsMessages.reservedFor)} ${oldSeatingAreaDescription}` : ''),
    [oldSeatingAreaDescription, formatMessage]
  )

  const formattedOldReservationTime = useMemo(() => {
    if (oldReservationTime) {
      const oldArrivalTime = TimeOnly.fromSafe(oldReservationTime)
      if (oldArrivalTime) {
        return TimeInterval.from(oldArrivalTime, oldArrivalTime.plus({ minutes: oldReservationDuration ?? 0 })).formatSTime(undefined, true)
      }
    }

    return undefined
  }, [oldReservationDuration, oldReservationTime])

  const formattedOldReservationDate = useMemo(() => {
    if (oldReservationDate) {
      const oldArrivalDate = DateOnly.fromSafe(oldReservationDate)
      if (oldArrivalDate) {
        return oldArrivalDate.formatNYearFMonthNDayFWeek()
      }
    }
    return ''
  }, [oldReservationDate])

  return (
    <CardSection width="100%" data-test="reservation-details">
      <AutoFallbackImage data-test="venue-image" width="100%" alt="Venue Header Image" fallback={fallback} imageUrl={imageUrl} />
      <VStack m="lm" spacing="lm">
        <ReservationDetailsHeader
          calendarLinks={calendarLinks}
          venueName={venueName}
          reservationReference={
            reservationReferenceId ? `${formatMessage(reservationDetailsMessages.reservationNumber)} ${reservationReferenceId}` : undefined
          }
        />
        {arrivalDate && (
          <ReservationDetailsItem
            icon="GX-calendar-range"
            text={arrivalDate.formatNYearFMonthNDayFWeek()}
            data-test="reservation-date"
            oldText={formattedOldReservationDate}
          />
        )}
        {(arrivalTime || timeSlotDescription) && (
          <ReservationDetailsItem
            icon="GX-clock-time-five"
            text={
              arrivalTime
                ? TimeInterval.from(arrivalTime, arrivalTime.plus({ minutes: reservationDuration ?? 0 })).formatSTime(undefined, true)
                : undefined
            }
            subText={timeSlotDescription}
            data-test="reservation-time"
            oldText={formattedOldReservationTime}
            oldSubText={oldTimeSlotDescription}
          />
        )}
        {(guestDisplayName || formattedPartySize) && (
          <ReservationDetailsItem
            icon="GX-account-multiple"
            text={guestDisplayName}
            subText={formattedPartySize}
            data-test="reservation-guest-name"
            oldSubText={formattedOldPartySize}
          />
        )}
        {seatingAreaDescription && (
          <ReservationDetailsItem
            icon="GX-table-chair"
            text={`${formatMessage(reservationDetailsMessages.reservedFor)} ${seatingAreaDescription}`}
            data-test="reservation-seating-area"
            oldText={formattedOldSeatingAreaDescription}
          />
        )}
        {reservationMinimum && (
          <ReservationDetailsItem
            icon="GX-currency-usd"
            text={`${formatMessage(reservationDetailsMessages.tableMinimum)} ${reservationMinimum}`}
            data-test="reservation-minimum"
            oldText={formattedOldReservationMinimum}
          />
        )}
        {offerName && <ReservationDetailsItem icon="GX-table" text={offerName} data-test="reservation-offer-name" />}
      </VStack>
    </CardSection>
  )
}
